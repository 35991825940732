// 解析格式<zh_CN>content</zh_CN>
export function parseLangStr(str, lang) {
    if (str == undefined || str == null) {
        return ""
    }
    let langStart = `<${lang}>`;
    let langEnd = `</${lang}>`;
    let startIndex = str.indexOf(langStart);
    let endIndex = str.indexOf(langEnd);
    if (startIndex > -1 && endIndex > -1) {
        str = str.replace(langStart + "\n", langStart)
        str = str.replace(langEnd + "\n", langEnd)
        str = str.replace("\n" + langEnd + "\n", langEnd)
        str = str.replace("\n" + langEnd, langEnd)
        str = str.substring(startIndex + langStart.length, endIndex);
    }
    return str;
}

/**
 * 颜色转换16进制转rgba
 * @param {String} hex
 * @param {Number} opacity
 */
 export function hex2Rgba(hex, opacity) {
    if(!hex) hex = "#2c4dae";
    return "rgba(" + parseInt("0x" + hex.slice(1, 3)) + "," + parseInt("0x" + hex.slice(3, 5)) + "," + parseInt("0x" + hex.slice(5, 7)) + "," + (opacity || "1") + ")";
}

/**
 * 指定字符串 溢出显示省略号
 * @param {String} str
 * @param {Number} num
 */
export const getSubStringSum = (str = "", num = 1) => {
    let newStr;
    if(str){
        str = str + '';
        if (str.trim().length > num ) {
            newStr = str.trim().substring(0, num) + "...";
        } else {
            newStr = str.trim();
        }
    }else{
        newStr = ''
    }
    return newStr;
}


export function formate(str, ... args) {
    for (var i = 0; i < args.length; i++) {
        str = str.replace(new RegExp("\\/" + i + "\\/", "gm"), args[i]);
    }
    return str;
}

export function formateSeconds(value) {
    let result = ""
    let sencond = parseInt(value)
    //3600秒等于60分钟等于1小时
    if(sencond > 3600 * 24){  //表示大于一天
        let day = sencond / (3600 * 24);
        sencond = sencond % (3600 * 24);    //求天数整除外剩余的秒数
        let hour = sencond / 3600;          //1小时==60分钟==3600秒，所以剩余的秒数÷3600= 得到几个小时数
        result = parseInt(day)+"天"+parseInt(hour)+"时";
    } else if(sencond > 3600){     //表示大于一个小时
        let hour = sencond / 3600;
        let minute = sencond % 3600 / 60;         //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
        result = parseInt(hour)+"时"+parseInt(minute)+"分";
    } else if(sencond > 60){  //表示大于一分钟
        let minute = sencond / 60;
        let sec = sencond % 60;           //求分钟数整除外剩余的秒数
        result = parseInt(minute)+"分"+parseInt(sec)+"秒";
    } else {  //不大于一天、不大于一个小时、也不大于一分钟，那就是秒数
        result = ""+parseInt(sencond)+"秒";
    }
    return result
}