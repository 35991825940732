<template>
    <div class="accout">
                <!-- 綁定郵箱找回 -->
                <van-nav-bar  :title="$t('LAN.account_destroy')"      :left-text="$t('LAN.back')"       left-arrow        @click-left="goBack"  />

        
        <div class="content">
            <div v-if="step==0" >
                <div class="bottom-every">
                    <p v-html="$t(`LAN.account_destroy_content1`)"></p>
                </div>
                <div class="bottom-every">
                    <p v-html="$t(`LAN.account_destroy_content2`)"></p>
                </div>
                <div class="bottom-every">
                    <p v-html="$t(`LAN.account_destroy_content3`)"></p>
                </div>
                <div class="bottom-every">
                    <p v-html="$t(`LAN.account_destroy_content4`)"></p>
                </div>
                <div class="bottom-every">
                    <p v-html="$t(`LAN.account_destroy_content5`)"></p>
                </div>
                <div class="bottom-every">
                    <p v-html="$t(`LAN.account_destroy_content6`)"></p>
                </div>
                <div class="bottom-every">
                    <p v-html="$t(`LAN.account_destroy_content7`)"></p>
                </div>
                <div class="bottom-every">
                    <p v-html="$t(`LAN.account_destroy_content8`)"></p>
                </div>
                <div class="agree">
                    <van-checkbox v-model="checked" checked-color="#6387ea" @click="onChecked" >{{$t('LAN.account_destroy_read_tips')}}<a >{{$t('LAN.account_destroy_read_tips2')}}</a></van-checkbox>
                </div>
                <div style="margin: 16px; padding-bottom: 45px;">
                    <van-button round block color="#6e87ea" @click="onConfirmDestroy" :disabled="btnDisabled">{{btnText}}</van-button>
                </div>
            </div>

            <div v-if="step==1">
                <van-form @submit="onSubmit">
                    <van-field
                        v-model="email"
                        :label="$t('LAN.email')"
                        :placeholder="$t('LAN.input_email')"
                        clearable
                        center
                        left-icon="envelop-o"
                        :rules="[{ validator:validateEmail, required: true, message:  $t('LAN.input_validator_email') }]"
                    />
                    <van-field
                        v-model="verify"
                        name="verify"
                        :label="$t('LAN.verify_code')"
                        center
                        left-icon="envelop-o"
                        :placeholder="$t('LAN.input_new_verify_code')"
                        :rules="[{ required: true, message: $t('LAN.input_new_verify_code') }]">
                        <template #button>
                            <van-button size="small" type="primary" color="#f3b134" @click="sendVerify" :disabled="verifyDisabled">{{ verifyBtnText }}</van-button>
                        </template>
                    </van-field>

                    <div style="margin: 16px;">
                        <van-button round block color="#6e87ea" native-type="submit">{{$t('LAN.account_destroy_now')}}</van-button>
                    </div>
                </van-form>
                <div class="email-tips" >
                    <p>{{$t('LAN.tips')}}</p>
                    <p>{{$t('LAN.email_tips')}}</p>
                </div>
            </div>

            <!-- 注销倒计时 -->
            <div v-if="step==2" class="destroy-tips">
                <van-empty
                class="custom-image"
                :image='require(`@/assets/common/warning.png`)'/>
                <p>{{$t('LAN.account_destroy_time')}}<a>{{formate($t('LAN.account_destroy_time2'), destroyTime)}}</a></p>
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit" @click="onCancelDestroy">{{$t('LAN.account_destroy_cancel')}}</van-button>
                </div>
            </div>

            <!-- 已取消 -->
            <div v-if="step==3" class="destroy-tips">
                <van-empty
                class="custom-image"
                :image='require(`@/assets/common/success.png`)'/>
                <p>{{$t('LAN.account_destroy_cancel_tips')}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, onMounted, toRefs, getCurrentInstance } from 'vue'
import { useRoute,useRouter } from 'vue-router'

import { Toast } from 'vant'
import { requestEmailVerifycode, requestCancelInfo, requestCancelCreate, requestCancelDelete} from '@/service/user'
import { validateNull, validateEmail } from '@/utils/validate';
import { useI18n } from 'vue-i18n'
import { formate } from '@/utils/string'
import { useStore } from 'vuex'

export default {
    setup() {
        // 扩展语言
        const { proxy } = getCurrentInstance();
        const { t } = useI18n()
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const state = reactive({
            checked:false,
            time:0,
            btnDisabled: true,
            step:-1,
            btnText: t('LAN.account_destroy_now'),

            email: '',
            verify: '',
            verifyTime:0,
            verifyDisabled: false,
            verifyBtnText: t('LAN.send_verify_code'),

            destroyTime: 0,

            showBack: true,
        })

        onMounted(async () => {
            console.log(t(`LAN.account_destroy_content1`))
// http://192.168.0.202:8080/sdkweb/userCenter/user/accountdestroy?gameId=1000&userName=test1&password=888888&channel=3&lang=vi_VN&token=Bearer+eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiZXhwIjoxNjkzODI4Mjc3fQ.KAx_SP5lDN1EWkYmZnURob3zmSQkzjK6SwqXU53pKRc&userId=1

            if (Object.keys(route.query).length > 0) {
                store.dispatch("initGame", route.query)
                store.dispatch("initUser", route.query)
                proxy.$I(proxy,store.getters.lang)
                state.showBack = false
            }
            
            Toast.loading(t('LAN.loading'));
            let result = await requestCancelInfo();
            if (result.left == -1) {
                state.step = 0
                state.time = 5
                onTimer()
            } else {
                state.step = 2
                state.destroyTime = result.left;
            }
            Toast.clear();
        });

        const onChecked = ()  => {
            if (state.time > 0) {
                state.checked = false;
            } else {
                state.btnDisabled = !state.checked;
            }
        }

        const onTimer = () => {
           // console.log(state.time)
            if (state.time > 0) {
                state.time --;
                state.btnText = `${t('LAN.account_destroy_now')}(${state.time})`;
                setTimeout(onTimer, 1000);
            } else{
                state.time = 0;
                state.btnText = `${t('LAN.account_destroy_now')}`;
            }
        }

        const onConfirmDestroy = () => {
            if (validateNull(store.getters.email)) {
                onSubmit();
            } else {
                state.step = 1;
            }
        }

        const onSubmit = async () => {
            Toast.loading(t('LAN.sending'));
            await requestCancelCreate({
                verifyCode: state.verify,
            });
            state.destroyTime = 7;
            state.step = 2;
            Toast(t('LAN.success'));
        }

        const sendVerify = async () => {
            if (validateNull(state.email) || !validateEmail(state.email)) {
                Toast(t('LAN.input_validator_email'))
                return;
            }
            Toast.loading(t('LAN.sending'));
            await requestEmailVerifycode({email:state.email});
            state.verifyTime = 30
            onVerifyTimer()
            Toast.success(t('LAN.send_success'))
        }

        const onVerifyTimer = () => {
            if (state.verifyTime > 0) {
                state.verifyDisabled = true;
                state.verifyTime --;
                state.verifyBtnText = `${t('LAN.resend')}(${state.verifyTime})`;
                setTimeout(onVerifyTimer, 1000);
            } else{
                state.verifyTime = 0;
                state.oldVerifyBtnText = t('LAN.send_verify_code');
                state.verifyDisabled = false;
            }
        }

        const onCancelDestroy = async () => {
            Toast.loading(t('LAN.sending'));
            await requestCancelDelete({});
            state.step = 3;
            Toast(t('LAN.success'));
        }
        const goBack = () => {
            router.go(-1)
        }

        return {
            ...toRefs(state),
            formate,
            onChecked,
            validateEmail,
            onSubmit,
            sendVerify,
            onConfirmDestroy,
            onCancelDestroy,
            goBack
        }
    },
    components: {

    }
}
</script>

<style lang="less" scoped>

/deep/ .bottom-every {
    a {
        color: rgb(248, 26, 29);
        line-height: 1.714;
        text-align: justifyLeft;
    }
}

.bottom-every {
    margin: 10px 10px 10px;
    text-align: left;
    font-size: 15px;

    a {
        color: rgb(248, 26, 29);
        line-height: 1.714;
        text-align: justifyLeft;
    }
}

.accout {
    height: 100vh;

    .content {
        margin-top: 10px;
        height: calc(var(--vh, 1vh) * 100);
        overflow-y: scroll;
        .L{
            .bottom-every{
                text-align: right;
            }
        }
    }
}
.agree {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 16px;
    a {
        color: rgb(248, 26, 29);
    }
}

.email-tips {
    margin: 20px;
    text-align: left;
    color: #8c8c8c;
    font-size: 14px;
}

.destroy-tips {
    p {
        font-size: 18px;
    }
    a {
        color: rgb(248, 26, 29);
    }
}
</style>